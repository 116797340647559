export default {
    "defaultAddress": "0x58FCe385Ed16beB4BCE49c8DF34c7d6975807520",
    "contractName": "SignMessageLib",
    "version": "1.4.0",
    "released": false,
    "networkAddresses": {
        "5": "0x58FCe385Ed16beB4BCE49c8DF34c7d6975807520",
        "56": "0x58FCe385Ed16beB4BCE49c8DF34c7d6975807520",
        "100": "0x58FCe385Ed16beB4BCE49c8DF34c7d6975807520"
    },
    "abi": [
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "bytes32",
                    "name": "msgHash",
                    "type": "bytes32"
                }
            ],
            "name": "SignMsg",
            "type": "event"
        },
        {
            "inputs": [
                {
                    "internalType": "bytes",
                    "name": "message",
                    "type": "bytes"
                }
            ],
            "name": "getMessageHash",
            "outputs": [
                {
                    "internalType": "bytes32",
                    "name": "",
                    "type": "bytes32"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "bytes",
                    "name": "_data",
                    "type": "bytes"
                }
            ],
            "name": "signMessage",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        }
    ]
}
;