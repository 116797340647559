export default {
    "defaultAddress": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
    "released": true,
    "contractName": "CompatibilityFallbackHandler",
    "version": "1.3.0",
    "networkAddresses": {
        "1": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "3": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "4": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "5": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "10": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "11": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "12": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "18": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "25": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "28": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "39": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "40": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "41": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "42": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "50": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "51": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "56": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "61": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "63": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "69": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "82": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "83": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "97": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "100": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "106": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "108": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "111": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "122": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "123": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "137": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "246": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "250": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "288": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "300": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "321": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "322": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "336": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "338": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "420": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "588": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "592": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "595": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "599": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "686": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "787": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "1001": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "1008": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "1088": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "1101": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "1111": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "1112": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "1115": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "1116": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "1284": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "1285": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "1287": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "1294": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "1807": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "1984": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "2001": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "2002": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "2008": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "2019": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "2020": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "2221": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "2222": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "3737": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "4002": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "4689": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "4918": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "4919": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "5001": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "7341": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "7700": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "8217": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "9000": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "9001": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "9728": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "10000": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "10001": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "10200": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "11235": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "11437": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "12357": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "23294": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "42161": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "42170": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "42220": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "43113": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "43114": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "43288": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "44787": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "45000": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "47805": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "54211": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "56288": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "59140": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "71401": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "71402": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "73799": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "80001": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "84531": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "200101": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "200202": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "333999": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "421611": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "421613": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "534353": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "11155111": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "245022926": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "1313161554": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "1313161555": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "1666600000": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "1666700000": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "11297108099": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "11297108109": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4"
    },
    "abi": [
        {
            "inputs": [],
            "name": "NAME",
            "outputs": [
                {
                    "internalType": "string",
                    "name": "",
                    "type": "string"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "VERSION",
            "outputs": [
                {
                    "internalType": "string",
                    "name": "",
                    "type": "string"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "bytes",
                    "name": "message",
                    "type": "bytes"
                }
            ],
            "name": "getMessageHash",
            "outputs": [
                {
                    "internalType": "bytes32",
                    "name": "",
                    "type": "bytes32"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "contract GnosisSafe",
                    "name": "safe",
                    "type": "address"
                },
                {
                    "internalType": "bytes",
                    "name": "message",
                    "type": "bytes"
                }
            ],
            "name": "getMessageHashForSafe",
            "outputs": [
                {
                    "internalType": "bytes32",
                    "name": "",
                    "type": "bytes32"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "getModules",
            "outputs": [
                {
                    "internalType": "address[]",
                    "name": "",
                    "type": "address[]"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "bytes32",
                    "name": "_dataHash",
                    "type": "bytes32"
                },
                {
                    "internalType": "bytes",
                    "name": "_signature",
                    "type": "bytes"
                }
            ],
            "name": "isValidSignature",
            "outputs": [
                {
                    "internalType": "bytes4",
                    "name": "",
                    "type": "bytes4"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "bytes",
                    "name": "_data",
                    "type": "bytes"
                },
                {
                    "internalType": "bytes",
                    "name": "_signature",
                    "type": "bytes"
                }
            ],
            "name": "isValidSignature",
            "outputs": [
                {
                    "internalType": "bytes4",
                    "name": "",
                    "type": "bytes4"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                },
                {
                    "internalType": "uint256[]",
                    "name": "",
                    "type": "uint256[]"
                },
                {
                    "internalType": "uint256[]",
                    "name": "",
                    "type": "uint256[]"
                },
                {
                    "internalType": "bytes",
                    "name": "",
                    "type": "bytes"
                }
            ],
            "name": "onERC1155BatchReceived",
            "outputs": [
                {
                    "internalType": "bytes4",
                    "name": "",
                    "type": "bytes4"
                }
            ],
            "stateMutability": "pure",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                },
                {
                    "internalType": "bytes",
                    "name": "",
                    "type": "bytes"
                }
            ],
            "name": "onERC1155Received",
            "outputs": [
                {
                    "internalType": "bytes4",
                    "name": "",
                    "type": "bytes4"
                }
            ],
            "stateMutability": "pure",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                },
                {
                    "internalType": "bytes",
                    "name": "",
                    "type": "bytes"
                }
            ],
            "name": "onERC721Received",
            "outputs": [
                {
                    "internalType": "bytes4",
                    "name": "",
                    "type": "bytes4"
                }
            ],
            "stateMutability": "pure",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "targetContract",
                    "type": "address"
                },
                {
                    "internalType": "bytes",
                    "name": "calldataPayload",
                    "type": "bytes"
                }
            ],
            "name": "simulate",
            "outputs": [
                {
                    "internalType": "bytes",
                    "name": "response",
                    "type": "bytes"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "bytes4",
                    "name": "interfaceId",
                    "type": "bytes4"
                }
            ],
            "name": "supportsInterface",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "",
                    "type": "bool"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                },
                {
                    "internalType": "bytes",
                    "name": "",
                    "type": "bytes"
                },
                {
                    "internalType": "bytes",
                    "name": "",
                    "type": "bytes"
                }
            ],
            "name": "tokensReceived",
            "outputs": [],
            "stateMutability": "pure",
            "type": "function"
        }
    ]
}
;