export default {
    "defaultAddress": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
    "released": true,
    "contractName": "SimulateTxAccessor",
    "version": "1.3.0",
    "networkAddresses": {
        "1": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "3": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "4": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "5": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "10": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "11": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "12": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "18": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "25": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "28": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "39": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "40": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "41": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "42": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "50": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "51": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "56": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "61": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "63": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "69": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "82": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "83": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "97": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "100": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "106": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "108": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "111": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "122": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "123": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "137": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "246": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "250": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "288": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "300": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "321": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "322": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "336": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "338": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "420": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "588": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "592": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "595": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "599": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "686": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "787": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "1001": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "1008": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "1088": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "1101": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "1111": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "1112": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "1115": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "1116": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "1284": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "1285": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "1287": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "1294": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "1807": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "1984": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "2001": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "2002": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "2008": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "2019": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "2020": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "2221": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "2222": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "3737": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "4002": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "4689": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "4918": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "4919": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "5001": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "7341": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "7700": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "8217": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "9000": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "9001": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "9728": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "10000": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "10001": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "10200": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "11235": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "11437": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "12357": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "23294": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "42161": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "42170": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "42220": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "43113": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "43114": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "43288": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "44787": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "45000": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "47805": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "54211": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "56288": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "59140": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "71401": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "71402": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "73799": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "80001": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "84531": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "200101": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "200202": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "333999": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "421611": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "421613": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "534353": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "11155111": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "245022926": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "1313161554": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "1313161555": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "1666600000": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "1666700000": "0x727a77a074D1E6c4530e814F89E618a3298FC044",
        "11297108099": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da",
        "11297108109": "0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da"
    },
    "abi": [
        {
            "inputs": [],
            "stateMutability": "nonpayable",
            "type": "constructor"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "value",
                    "type": "uint256"
                },
                {
                    "internalType": "bytes",
                    "name": "data",
                    "type": "bytes"
                },
                {
                    "internalType": "enum Enum.Operation",
                    "name": "operation",
                    "type": "uint8"
                }
            ],
            "name": "simulate",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "estimate",
                    "type": "uint256"
                },
                {
                    "internalType": "bool",
                    "name": "success",
                    "type": "bool"
                },
                {
                    "internalType": "bytes",
                    "name": "returnData",
                    "type": "bytes"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        }
    ]
}
;