export default {
    "defaultAddress": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
    "released": true,
    "contractName": "MultiSendCallOnly",
    "version": "1.3.0",
    "networkAddresses": {
        "1": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "3": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "4": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "5": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "10": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "11": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "12": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "18": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "25": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "28": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "39": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "40": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "41": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "42": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "50": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "51": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "56": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "61": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "63": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "69": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "82": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "83": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "97": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "100": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "106": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "108": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "111": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "122": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "123": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "137": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "246": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "250": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "288": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "300": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "321": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "322": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "336": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "338": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "420": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "588": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "592": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "595": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "599": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "686": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "787": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "1001": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "1008": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "1088": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "1101": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "1111": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "1112": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "1115": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "1116": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "1284": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "1285": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "1287": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "1294": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "1807": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "1984": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "2001": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "2002": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "2008": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "2019": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "2020": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "2221": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "2222": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "3737": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "4002": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "4689": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "4918": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "4919": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "5001": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "7341": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "7700": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "8217": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "9000": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "9001": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "9728": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "10000": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "10001": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "10200": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "11235": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "11437": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "12357": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "23294": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "42161": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "42170": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "42220": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "43113": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "43114": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "43288": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "44787": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "45000": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "47805": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "54211": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "56288": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "59140": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "71401": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "71402": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "73799": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "80001": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "84531": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "200101": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "200202": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "333999": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "421611": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "421613": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "534353": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "11155111": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "245022926": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "1313161554": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "1313161555": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "1666600000": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "1666700000": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "11297108099": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "11297108109": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D"
    },
    "abi": [
        {
            "inputs": [
                {
                    "internalType": "bytes",
                    "name": "transactions",
                    "type": "bytes"
                }
            ],
            "name": "multiSend",
            "outputs": [],
            "stateMutability": "payable",
            "type": "function"
        }
    ]
}
;