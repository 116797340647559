export default {
    "defaultAddress": "0x9b35Af71d77eaf8d7e40252370304687390A1A52",
    "released": false,
    "contractName": "CreateCall",
    "version": "1.4.0",
    "networkAddresses": {
        "5": "0x9b35Af71d77eaf8d7e40252370304687390A1A52",
        "56": "0x9b35Af71d77eaf8d7e40252370304687390A1A52",
        "100": "0x9b35Af71d77eaf8d7e40252370304687390A1A52"
    },
    "abi": [
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "newContract",
                    "type": "address"
                }
            ],
            "name": "ContractCreation",
            "type": "event"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "value",
                    "type": "uint256"
                },
                {
                    "internalType": "bytes",
                    "name": "deploymentData",
                    "type": "bytes"
                }
            ],
            "name": "performCreate",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "newContract",
                    "type": "address"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "value",
                    "type": "uint256"
                },
                {
                    "internalType": "bytes",
                    "name": "deploymentData",
                    "type": "bytes"
                },
                {
                    "internalType": "bytes32",
                    "name": "salt",
                    "type": "bytes32"
                }
            ],
            "name": "performCreate2",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "newContract",
                    "type": "address"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        }
    ]
}
;