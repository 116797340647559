export default {
    "defaultAddress": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
    "released": true,
    "contractName": "GnosisSafeProxyFactory",
    "version": "1.3.0",
    "networkAddresses": {
        "1": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "3": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "4": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "5": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "10": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "11": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "12": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "18": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "25": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "28": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "39": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "40": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "41": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "42": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "50": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "51": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "56": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "61": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "63": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "69": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "82": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "83": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "97": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "100": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "106": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "108": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "111": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "122": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "123": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "137": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "246": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "250": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "288": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "300": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "321": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "322": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "336": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "338": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "420": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "588": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "592": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "595": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "599": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "686": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "787": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "1001": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "1008": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "1088": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "1101": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "1111": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "1112": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "1115": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "1116": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "1284": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "1285": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "1287": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "1294": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "1807": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "1984": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "2001": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "2002": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "2008": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "2019": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "2020": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "2221": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "2222": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "3737": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "4002": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "4689": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "4918": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "4919": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "5001": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "7341": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "7700": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "8217": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "9000": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "9001": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "9728": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "10000": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "10001": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "10200": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "11235": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "11437": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "12357": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "23294": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "42161": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "42170": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "42220": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "43113": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "43114": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "43288": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "44787": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "45000": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "47805": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "54211": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "56288": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "59140": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "71401": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "71402": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "73799": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "80001": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "84531": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "200101": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "200202": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "333999": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "421611": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "421613": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "534353": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "11155111": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "245022926": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "1313161554": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "1313161555": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "1666600000": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "1666700000": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "11297108099": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "11297108109": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2"
    },
    "abi": [
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": false,
                    "internalType": "contract GnosisSafeProxy",
                    "name": "proxy",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "address",
                    "name": "singleton",
                    "type": "address"
                }
            ],
            "name": "ProxyCreation",
            "type": "event"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_singleton",
                    "type": "address"
                },
                {
                    "internalType": "bytes",
                    "name": "initializer",
                    "type": "bytes"
                },
                {
                    "internalType": "uint256",
                    "name": "saltNonce",
                    "type": "uint256"
                }
            ],
            "name": "calculateCreateProxyWithNonceAddress",
            "outputs": [
                {
                    "internalType": "contract GnosisSafeProxy",
                    "name": "proxy",
                    "type": "address"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "singleton",
                    "type": "address"
                },
                {
                    "internalType": "bytes",
                    "name": "data",
                    "type": "bytes"
                }
            ],
            "name": "createProxy",
            "outputs": [
                {
                    "internalType": "contract GnosisSafeProxy",
                    "name": "proxy",
                    "type": "address"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_singleton",
                    "type": "address"
                },
                {
                    "internalType": "bytes",
                    "name": "initializer",
                    "type": "bytes"
                },
                {
                    "internalType": "uint256",
                    "name": "saltNonce",
                    "type": "uint256"
                },
                {
                    "internalType": "contract IProxyCreationCallback",
                    "name": "callback",
                    "type": "address"
                }
            ],
            "name": "createProxyWithCallback",
            "outputs": [
                {
                    "internalType": "contract GnosisSafeProxy",
                    "name": "proxy",
                    "type": "address"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_singleton",
                    "type": "address"
                },
                {
                    "internalType": "bytes",
                    "name": "initializer",
                    "type": "bytes"
                },
                {
                    "internalType": "uint256",
                    "name": "saltNonce",
                    "type": "uint256"
                }
            ],
            "name": "createProxyWithNonce",
            "outputs": [
                {
                    "internalType": "contract GnosisSafeProxy",
                    "name": "proxy",
                    "type": "address"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "proxyCreationCode",
            "outputs": [
                {
                    "internalType": "bytes",
                    "name": "",
                    "type": "bytes"
                }
            ],
            "stateMutability": "pure",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "proxyRuntimeCode",
            "outputs": [
                {
                    "internalType": "bytes",
                    "name": "",
                    "type": "bytes"
                }
            ],
            "stateMutability": "pure",
            "type": "function"
        }
    ]
}
;