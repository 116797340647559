export default {
    "defaultAddress": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
    "released": true,
    "contractName": "CreateCall",
    "version": "1.3.0",
    "networkAddresses": {
        "1": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "3": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "4": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "5": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "10": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "11": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "12": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "18": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "25": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "28": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "39": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "40": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "41": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "42": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "50": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "51": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "56": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "61": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "63": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "69": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "82": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "83": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "97": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "100": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "106": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "108": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "111": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "122": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "123": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "137": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "246": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "250": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "288": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "300": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "321": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "322": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "336": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "338": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "420": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "588": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "592": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "595": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "599": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "686": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "787": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "1001": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "1008": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "1088": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "1101": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "1111": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "1112": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "1115": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "1116": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "1284": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "1285": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "1287": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "1294": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "1807": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "1984": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "2001": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "2002": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "2008": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "2019": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "2020": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "2221": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "2222": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "3737": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "4002": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "4689": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "4918": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "4919": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "5001": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "7341": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "7700": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "8217": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "9000": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "9001": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "9728": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "10000": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "10001": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "10200": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "11235": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "11437": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "12357": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "23294": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "42161": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "42170": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "42220": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "43113": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "43114": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "43288": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "44787": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "45000": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "47805": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "54211": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "56288": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "59140": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "71401": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "71402": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "73799": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "80001": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "84531": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "200101": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "200202": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "333999": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "421611": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "421613": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "534353": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "11155111": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "245022926": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "1313161554": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "1313161555": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "1666600000": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "1666700000": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "11297108099": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "11297108109": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4"
    },
    "abi": [
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": false,
                    "internalType": "address",
                    "name": "newContract",
                    "type": "address"
                }
            ],
            "name": "ContractCreation",
            "type": "event"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "value",
                    "type": "uint256"
                },
                {
                    "internalType": "bytes",
                    "name": "deploymentData",
                    "type": "bytes"
                }
            ],
            "name": "performCreate",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "newContract",
                    "type": "address"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "value",
                    "type": "uint256"
                },
                {
                    "internalType": "bytes",
                    "name": "deploymentData",
                    "type": "bytes"
                },
                {
                    "internalType": "bytes32",
                    "name": "salt",
                    "type": "bytes32"
                }
            ],
            "name": "performCreate2",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "newContract",
                    "type": "address"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        }
    ]
}
;